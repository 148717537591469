import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import MLPic from '../Images/ML.jpg';
import DT from '../Images/DT.webp'
import AIC from '../Images/AIC.webp'
import CAS from '../Images/CAS.jpg'
import NLP from '../Images/NLP.jpg'
import IRC from '../Images/IRC.webp'
import ADA from '../Images/ADA.jpg'
import AIPChat from '../Images/AIPCha.avif'
import PredictiveAnalyticsSoftware from '../Images/PredictiveAnalyticsSoftware.jpg'

const ML = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <Container fluid className='min-vh-100 d-flex align-items-center justify-content-center' style={{ padding: 0, backgroundColor: '#f8f9fa' }}>
            <Row className='w-100'>
                <Col md={12} className='d-flex justify-content-center align-items-center'>
                    <Card className='shadow-lg w-100' style={{ maxWidth: '100%', border: 'none' }}>
                        <Card.Title className='text-center display-4 mb-4 fw-bolder'>Machine Learning</Card.Title>
                        {/* <hr className='' style={{ color: 'black' }} /> */}
                        <Card.Text className='text-center fs-5 mb-4'>
                            Discover the transformative power of Machine Learning (ML) and its impact on technology and society.
                        </Card.Text>
                        <Card.Body className='p-5'>
                            <Card.Img variant="top" src={MLPic} alt="Machine Learning" style={{ width: '100%', height: 'auto', maxHeight: '600px' }} />
                            <Card.Text>
                                <div>
                                    <div className='row mb-4 bg-light p-2'>
                                        <center className='my-3 fs-2'>
                                            Services and Products
                                        </center>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Data Training
                                                </div>
                                                <Card.Img variant="top" src={DT} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Providing the best Machine Learning tools for training your devices and bots with cutting-edge algorithms and techniques.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    AI Consulting
                                                </div>
                                                <Card.Img variant="top" src={AIC} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Expert AI consulting services to help businesses implement AI strategies, optimize processes, and drive innovation.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Custom AI Solutions
                                                </div>
                                                <Card.Img variant="top" src={CAS} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Tailored AI solutions designed to meet the unique needs of your business, from automation to predictive analytics.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    AI-Driven Automation
                                                </div>
                                                <Card.Img variant="top" src={ADA} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Implement AI-driven automation to enhance productivity, reduce errors, and streamline operations across your organization.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    AI-Powered Chatbots
                                                </div>
                                                <Card.Img variant="top" src={AIPChat} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Revolutionize customer support with AI-powered chatbots capable of handling complex queries with ease.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Predictive Analytics Software
                                                </div>
                                                <Card.Img variant="top" src={PredictiveAnalyticsSoftware} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Leverage our advanced predictive analytics software to make data-driven decisions and forecast trends accurately.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Image Recognition Tools
                                                </div>
                                                <Card.Img variant="top" src={IRC} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Deploy cutting-edge image recognition tools for applications ranging from security to content management.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 col-sl-4 col-xxl-4 fs-5 mb-1 '>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Natural Language Processing
                                                </div>
                                                <Card.Img variant="top" src={NLP} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Utilize our NLP tools to analyze and interpret human language, enhancing communication and information retrieval.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Card.Text>
                            <Card.Text className='mb-4 fs-6'>
                                <h5>About Machine Learning</h5>
                                <p className='lead'> Machine Learning (ML) is a branch of artificial intelligence (AI) that focuses on the development of algorithms that allow computers to learn and make decisions based on data. Unlike traditional software, which requires explicit programming for every task, ML algorithms improve their performance as they are exposed to more data. This ability to learn from and adapt to new information makes ML incredibly powerful for a wide range of applications.</p>
                            </Card.Text>
                            <Card.Text className='mb-4 fs-6'>
                                <h5>Applications of Machine Learning:</h5>
                                <ul className="lead">
                                    <li>Healthcare: ML algorithms can predict patient diagnoses, recommend treatments, and even identify patterns in medical data to advance research.</li>
                                    <li>Finance: Used for fraud detection, credit scoring, algorithmic trading, and personalized financial recommendations.</li>
                                    <li>Retail: Enhances customer experience through personalized recommendations, inventory management, and sales forecasting.</li>
                                    <li>Transportation: Powers autonomous vehicles, optimizes route planning, and improves logistics efficiency.</li>
                                    <li>Entertainment: Powers content recommendations on platforms like Netflix and Spotify, and can even create new content.</li>
                                </ul>
                            </Card.Text>

                            <Card.Text className='mb-4 fs-6'>
                                <h5>Notable Case Studies:</h5>
                                <ul className="lead">
                                    <li>DeepMind's AlphaGo: An AI developed by DeepMind that defeated a world champion in the game of Go, showcasing the power of reinforcement learning.</li>
                                    <li>IBM Watson: Known for winning Jeopardy!, Watson uses natural language processing and machine learning to analyze and interpret vast amounts of data.</li>
                                    <li>Google Photos: Utilizes ML for image recognition and tagging, making it easier to search for and organize photos.</li>
                                    <li>Amazon Alexa: Combines speech recognition and machine learning to understand and respond to user commands, enhancing smart home interactions.</li>
                                </ul>
                            </Card.Text>

                            <Card.Text className='mb-4 fs-6'>
                                <h5>Key Tools & Libraries:</h5>
                                <ul className="lead">
                                    <li>scikit-learn: A Python library for simple and efficient tools for data mining and data analysis.</li>
                                    <li>TensorFlow: An open-source library developed by Google for numerical computation and machine learning.</li>
                                    <li>Keras: An open-source software library that provides a Python interface for neural networks.</li>
                                    <li>PyTorch: An open-source machine learning library based on the Torch library, developed by Facebook's AI Research lab.</li>
                                </ul>
                            </Card.Text>

                            <Card.Text className='mb-4 fs-6'>
                                <h5>Future Trends in Machine Learning:</h5>
                                <ul className="lead">
                                    <li>Explainable AI: As ML models become more complex, there will be a growing need for transparency in how decisions are made.</li>
                                    <li>Edge Computing: ML models will increasingly be deployed on edge devices to provide real-time analytics and reduce latency.</li>
                                    <li>Automated Machine Learning (AutoML): Tools and techniques to automate the process of applying machine learning to real-world problems.</li>
                                    <li>Ethics and Bias: Addressing the ethical implications of AI and ensuring that ML systems are fair and unbiased.</li>
                                </ul>
                            </Card.Text>

                            <div className='text-center'>
                                <Button variant='primary' size='lg' onClick={handleShow}>Learn More</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal for Read More */}
            <Modal show={showModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Deep Dive into Machine Learning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Machine Learning Models:</h5>
                    <div>
                        <div>
                            <div>Supervised Learning: Involves training a model on labeled data, which means that the input data is paired with the correct output. Common algorithms include Linear Regression, Decision Trees, and Support Vector Machines (SVM).</div>
                        </div>
                        <div>
                            <div>Unsupervised Learning: The model learns from unlabeled data, trying to find hidden patterns or intrinsic structures. Examples include Clustering (e.g., K-means) and Dimensionality Reduction (e.g., PCA).</div>
                        </div>
                        <div>
                            <div>Reinforcement Learning: The model learns by interacting with an environment, receiving feedback through rewards or penalties. This method is commonly used in gaming and robotics.</div>
                        </div>
                    </div>

                    <h5>Challenges in Machine Learning:</h5>
                    <div>
                        <div>
                            <div>Data Quality: The success of ML models heavily depends on the quality of the data. Noisy, incomplete, or biased data can lead to inaccurate predictions.</div>
                        </div>
                        <div>
                            <div>Overfitting and Underfitting: Overfitting occurs when a model is too complex and captures noise instead of the underlying pattern. Underfitting happens when a model is too simple and fails to capture the pattern.</div>
                        </div>
                        <div>
                            <div>Scalability: As the volume of data grows, ensuring that ML models scale effectively to handle large datasets is crucial.</div>
                        </div>
                    </div>

                    <h5>Future Trends:</h5>
                    <div>
                        <div>
                            <div>Explainable AI: As ML models become more complex, there will be a growing need for transparency in how decisions are made.</div>
                        </div>
                        <div>
                            <div>Edge Computing: ML models will increasingly be deployed on edge devices to provide real-time analytics and reduce latency.</div>
                        </div>
                        <div>
                            <div>Automated Machine Learning (AutoML): Tools and techniques to automate the process of applying machine learning to real-world problems.</div>
                        </div>
                        <div>
                            <div>Ethics and Bias: Addressing the ethical implications of AI and ensuring that ML systems are fair and unbiased.</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ML;
