import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ImageDetectionPic from '../Images/ImageDetection.avif';
import SmartHomePic from '../Images/SmartHome.jpg';
import DynamicWebsitesPic from '../Images/DynamicWebsites.jpg';
import RoboticArmsPic from '../Images/RoboticArms.jpg';
import POSPic from '../Images/POS.png';
import IOTPic from '../Images/IOT.jpg';

const IoTProjects = () => {
    return (
        <Container fluid className='min-vh-100 d-flex align-items-center justify-content-center' style={{ padding: 0, backgroundColor: '#f8f9fa' }}>
            <Row className='w-100'>
                <Col md={12} className='d-flex justify-content-center align-items-center'>
                    <Card className='shadow-lg w-100' style={{ maxWidth: '100%', border: 'none' }}>
                        <Card.Title className='text-center display-3 mt-3 fw-bold'>Internet of Things</Card.Title>
                        <Card.Text className='text-center fs-5 mb-4'>
                            Explore innovative IoT solutions that transform everyday technology into smart, interconnected systems.
                        </Card.Text>
                        <Card className='shadow-lg w-100' style={{ maxWidth: '100%', border: 'none' }}>
                            <Card.Body className='px-5 pb-5'>
                                <Card.Img variant="top" src={IOTPic} alt="IoT Projects" style={{ width: '100%', height: 'auto', maxHeight: '600px' }} />

                                
                                <Card.Text className='my-4'>
                                    <div className='row mb-4 bg-light p-2'>
                                        <center className='my-3 fs-2'>
                                            Services and Products
                                        </center>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '250px' }}>
                                                <div className='card-header'>Home Automation</div>
                                                <Card.Img variant="top" src={SmartHomePic} alt="Smart Home Automation" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Automate your home environment with smart devices that can be controlled remotely. Enhance convenience, security, and energy efficiency by integrating sensors, smart appliances, and automated systems into your home.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '250px' }}>
                                                <div className='card-header'>Robotic Arms</div>
                                                <Card.Img variant="top" src={RoboticArmsPic} alt="Robotic Arms" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Deploy robotic arms for precise and automated operations in manufacturing, assembly, and other fields. Improve productivity and accuracy with advanced robotic systems designed for specific tasks.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '250px' }}>
                                                <div className='card-header'>Dynamic Websites</div>
                                                <Card.Img variant="top" src={DynamicWebsitesPic} alt="Dynamic Websites" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Create websites that adapt to user interactions and provide real-time updates. Build engaging and responsive web applications that dynamically respond to user inputs and data changes.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '250px' }}>
                                                <div className='card-header'>POS</div>
                                                <Card.Img variant="top" src={POSPic} alt="POS System" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Revolutionize retail operations with a modern POS system that integrates seamlessly with inventory management, transaction processing, and reporting tools. Enhance customer experiences and streamline operations with a comprehensive POS solution.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Text>
                                <Card.Text className='my-4'>
                                    <h5>Overview of IoT Projects</h5>
                                    <p className='lead'>The Internet of Things (IoT) refers to the network of interconnected devices that communicate and exchange data with each other. IoT projects leverage this network to create smarter systems and solutions that enhance efficiency, convenience, and control in various domains.</p>
                                </Card.Text>

                                <Card.Text className='my-4'>
                                    <h5>Applications of IoT</h5>
                                    <ul className="lead">
                                        <li>Smart Homes: Automate and control home appliances, lighting, and security systems remotely.</li>
                                        <li>Industrial Automation: Enhance manufacturing processes with connected machinery and real-time data monitoring.</li>
                                        <li>Healthcare: Monitor patient health remotely and manage medical devices with IoT solutions.</li>
                                        <li>Smart Cities: Optimize urban infrastructure, such as traffic management and waste management, using IoT technology.</li>
                                        <li>Retail: Improve customer experience and operational efficiency with smart inventory systems and personalized offers.</li>
                                    </ul>
                                </Card.Text>
                                <Card.Text className='mb-4'>
                                    <h5>Notable Case Studies:</h5>
                                    <ul className="lead">
                                        <li>Google Nest: Provides smart thermostats and home security solutions that learn user preferences and optimize home settings.</li>
                                        <li>Philips Hue: Smart lighting system that allows users to control and customize their lighting environment from mobile devices.</li>
                                        <li>Amazon AWS IoT: Offers a range of cloud services for managing and analyzing IoT data, powering smart applications across industries.</li>
                                        <li>IBM Watson IoT: Helps enterprises leverage IoT data to improve operations, enhance customer experiences, and drive innovation.</li>
                                    </ul>
                                </Card.Text>
                                <Card.Text className='mb-4'>
                                    <h5>Key Tools & Technologies:</h5>
                                    <ul className="lead">
                                        <li>IoT Platforms: Platforms like AWS IoT, Microsoft Azure IoT, and Google Cloud IoT offer services for device management and data analytics.</li>
                                        <li>Connectivity Protocols: Protocols such as MQTT, CoAP, and HTTP enable communication between IoT devices and applications.</li>
                                        <li>Sensors & Actuators: Devices that collect data and perform actions based on IoT system inputs.</li>
                                        <li>Edge Computing: Processing data closer to the source to reduce latency and improve system performance.</li>
                                    </ul>
                                </Card.Text>
                                <Card.Text className='mb-4'>
                                    <h5>Future Trends in IoT:</h5>
                                    <ul className="lead">
                                        <li>5G Connectivity: Enhancing IoT performance with faster and more reliable network connections.</li>
                                        <li>Edge AI: Combining AI with edge computing to enable real-time decision-making and processing at the device level.</li>
                                        <li>IoT Security: Developing advanced security measures to protect IoT devices and data from cyber threats.</li>
                                        <li>Integration with Blockchain: Improving data integrity and security in IoT systems with blockchain technology.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default IoTProjects;
