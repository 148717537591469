import React from 'react';
import ML from './ML';
import AI from './AI';
import IOT from './IOT';
import Logo from '../Images/Logo.png'

const Landing = () => {
    return (
        <div style={{ minWidth: '400px' }}>
            <nav id="navbar-example2" className="navbar bg-body-tertiary bg-white shadow   px-3 mb-3 sticky-top">
                <a className="navbar-brand  fw-bold fs-3" href="/">
                    <div className='d-flex aling-items-center'>
                        <img src={Logo} style={{ width: "40px", height: "40px" }} className='rounded-circle bg-black me-2' />
                        <span>Basic to AI</span>
                    </div>
                </a>
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a className="nav-link rounded-0 fs-5 mx-2 text-center" href="#MachineLearning" style={{ minWidth: '50px' }}>ML</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link rounded-0 fs-5 mx-2 text-center" href="#ArtificialIntelligence" style={{ minWidth: '50px' }}>AI</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link rounded-0 fs-5 mx-2 text-center" href="#InternetOfThings" style={{ minWidth: '50px' }}>IOT</a>
                    </li>
                </ul>
            </nav>
            <div className=''>
                <div
                    data-bs-spy="scroll"
                    data-bs-target="#navbar-example2"
                    data-bs-root-margin="0px 0px -40%"
                    data-bs-smooth-scroll="true"
                    className="scrollspy-example bg-body-tertiary p-3 rounded-2"
                    tabIndex="0"
                >
                    <h4 id="MachineLearning" className='py-2 mb-4'><ML /></h4>
                    <h4 id="ArtificialIntelligence" className='py-2 my-4'><AI /></h4>
                    <h4 id="InternetOfThings" className='py-2 my-4'><IOT /></h4>
                </div>
            </div>
            <div class="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p class="col-md-4 mb-0 text-muted">© 2024 Basic to AI, B2A Inc.</p>

                    <ul class="nav col-md-4 justify-content-end">
                        <li class="nav-item"><a className="nav-link fs-5 text-center" href="#MachineLearning">ML</a></li>
                        <li class="nav-item"><a className="nav-link fs-5 text-center" href="#ArtificialIntelligence">AI</a></li>
                        <li class="nav-item"><a className="nav-link fs-5 text-center" href="#InternetOfThings">IOT</a></li>
                    </ul>
                </footer>
            </div>
        </div>
    );
}

export default Landing;
