import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import AIPic from '../Images/AIPic.jpg';
import APDM from '../Images/APDM.jpg';
import SmartRobotics from '../Images/SmartRobotics.jpg';
import NLPS from '../Images/NLPS.jpeg';
import ABSAT from '../Images/ABSAT.avif';
import ImageDetectionPic from '../Images/ImageDetection.avif';

const AI = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <Container fluid className='min-vh-100 d-flex align-items-center justify-content-center' style={{ padding: 0, backgroundColor: '#f8f9fa' }}>
            <Row className='w-100'>
                <Col md={12} className='d-flex justify-content-center align-items-center'>
                    <Card className='shadow-lg w-100' style={{ maxWidth: '100%', border: 'none' }}>
                        <Card.Title className='text-center display-3 mt-3 fw-bold'>Artificial Intelligence</Card.Title>
                        <Card.Text className='text-center'>
                            Dive into the realm of intelligent machines and systems.
                        </Card.Text>
                        <hr className='hr'/>
                        <Card.Body className='px-5 pb-5'>
                            <Card.Img variant="top" src={AIPic} alt="Machine Learning" style={{ width: '100%', height: 'auto',maxHeight:'600px' }} className='shadow' />
                            <Card.Text>
                                <div>
                                    <div className='row mb-4 bg-light p-2'>
                                        <center className='my-3 fs-2'>
                                            Services and Products
                                        </center>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    AI-Powered Decision Making
                                                </div>
                                                <Card.Img variant="top" src={APDM} alt="Image Detection" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Enable your organization to make data-driven decisions with AI-powered analytics tools that provide actionable insights. Harness the power of AI to optimize business strategies, enhance performance, and stay ahead of the competition.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Natural Language Processing Solutions
                                                </div>
                                                <Card.Img variant="top" src={NLPS} alt="Image Detection" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Unlock the potential of unstructured data with advanced NLP solutions. From sentiment analysis to language translation, our NLP tools enable businesses to better understand and interact with their customers, driving engagement and satisfaction.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '250px' }}>
                                            <div className='card-header'>
                                                    Face Recognition
                                                </div>
                                                <Card.Img variant="top" src={ImageDetectionPic} alt="Image Detection" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Implement advanced image detection systems to automatically recognize and categorize objects within digital images. Useful in security systems, quality control, and any application requiring visual analysis.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    AI-Based Sentiment Analysis Tools
                                                </div>
                                                <Card.Img variant="top" src={ABSAT} alt="Image Detection" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Understand customer emotions and sentiments with AI-driven sentiment analysis tools. Analyze social media, reviews, and feedback to gauge customer satisfaction and inform business strategies.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-3 col-md-6 fs-5 mb-1'>
                                            <div className='card shadow hoverMe' style={{ minHeight: '150px' }}>
                                                <div className='card-header'>
                                                    Smart Robotics
                                                </div>
                                                <Card.Img variant="top" src={SmartRobotics} alt="Image Detection" style={{ height: '150px', objectFit: 'cover' }} />
                                                <div className='card-body fs-6 lead'>
                                                    <p>Equip your operations with AI-integrated robots capable of performing complex tasks with precision. From manufacturing to healthcare, our smart robotics solutions enhance efficiency, reduce operational costs, and improve safety.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Text>
                            <Card.Text className='my-4'>
                                <h5>About Artificial Intelligence</h5>
                                <p className='lead'>Artificial Intelligence (AI) refers to the simulation of human intelligence in machines that are programmed to think, learn, and make decisions. AI encompasses a broad range of techniques, including machine learning, natural language processing, robotics, and computer vision. AI systems can perform tasks that typically require human intelligence, such as visual perception, speech recognition, decision-making, and language translation.</p>
                            </Card.Text>
                            <Card.Text className='mb-4'>
                                <h5>Applications of Artificial Intelligence:</h5>
                                <ul className="lead">
                                    <li>Healthcare: AI aids in diagnostics, personalized treatment, and drug discovery, and can predict patient outcomes.</li>
                                    <li>Finance: AI is used for fraud detection, algorithmic trading, and personalized financial advice.</li>
                                    <li>Retail: Enhances customer experiences through personalized recommendations and efficient inventory management.</li>
                                    <li>Transportation: Powers autonomous vehicles and optimizes traffic management systems.</li>
                                    <li>Entertainment: AI personalizes content recommendations and is used in the creation of digital media.</li>
                                </ul>
                            </Card.Text>
                            <Card.Text className='mb-4'>
                                <h5>Notable Case Studies:</h5>
                                <ul className="lead">
                                    <li>DeepMind's AlphaGo: Demonstrated AI's capabilities by defeating a world champion Go player.</li>
                                    <li>IBM Watson: Showcased AI's ability in natural language processing and decision-making in healthcare and other industries.</li>
                                    <li>Sophia: A social humanoid robot developed by Hanson Robotics, capable of human-like conversations.</li>
                                    <li>Tesla Autopilot: An AI-based system that provides semi-autonomous driving capabilities.</li>
                                </ul>
                            </Card.Text>
                            <Card.Text className='mb-4'>
                                <h5>Key Tools & Technologies:</h5>
                                <ul className="lead">
                                    <li>TensorFlow: An open-source platform for machine learning and AI development.</li>
                                    <li>OpenAI GPT: A language model developed by OpenAI for natural language processing.</li>
                                    <li>ROS (Robot Operating System): A flexible framework for writing robot software.</li>
                                    <li>IBM Watson: An AI system that can analyze unstructured data for various applications.</li>
                                </ul>
                            </Card.Text>
                            <Card.Text className='mb-4'>
                                <h5>Future Trends in Artificial Intelligence:</h5>
                                <ul className="lead">
                                    <li>General AI: Developing AI systems that can perform any intellectual task that a human can do.</li>
                                    <li>AI Ethics: Addressing the ethical concerns related to AI, including privacy, bias, and job displacement.</li>
                                    <li>Human-AI Collaboration: Enhancing how humans and AI work together in various fields.</li>
                                    <li>AI in Creative Arts: AI is increasingly being used to create music, art, and literature.</li>
                                </ul>
                            </Card.Text>
                            <div className='text-center'>
                                <Button variant='primary' size='lg' onClick={handleShow}>Learn More</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal for Read More */}
            <Modal show={showModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Deep Dive into Artificial Intelligence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Types of Artificial Intelligence:</h5>
                    <ul className="lead">
                        <li>Narrow AI: AI systems designed to perform a specific task, such as speech recognition or image classification.</li>
                        <li>General AI: AI that can perform any intellectual task that a human can do, still largely theoretical.</li>
                        <li>Superintelligent AI: AI that surpasses human intelligence, a concept often explored in science fiction.</li>
                    </ul>

                    <h5>Challenges in AI:</h5>
                    <div>
                        <div>Data Quality: AI systems require vast amounts of high-quality data to function effectively.</div>
                        <div>Bias and Fairness: AI systems can inherit biases from training data, leading to unfair outcomes.</div>
                        <div>Transparency: Understanding and explaining AI decisions is crucial, especially in critical applications.</div>
                    </div>

                    <h5>Future of AI:</h5>
                    <ul className="lead">
                        <li>AI and Ethics: Developing frameworks for responsible AI use.</li>
                        <li>AI in Edge Computing: Deploying AI models on devices closer to the data source.</li>
                        <li>AI in Healthcare: Advanced diagnostics and personalized treatments.</li>
                        <li>AI in Autonomous Systems: Enhancing the capabilities of robots and vehicles.</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default AI;
